<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家資料</li>
            <li class="breadcrumb-item active" aria-current="page">店家類型管理</li>
            <li class="breadcrumb-item active" aria-current="page">店家類型編輯</li>
        </ol>
        </nav>
        <hr class="w-100">
        <div class="col-12 row mx-auto data-form">
            <Form @submit="store_subtype_save()" :validation-schema="store_subtype_schema" @invalid-submit="on_invalid_submit">
                <div class="mb-3">
                    <label for="store_type" class="form-label"><span class="required">*</span>店家類型</label>
                    <div class="input-group">
                        <Field name="store_type" v-slot="{ meta, field }" v-model="store_subtype_data.type_id">
                            <select
                                id="store_type"
                                v-bind="field"
                                class="form-select"
                                :class="{
                                    'is-valid': meta.valid && meta.touched,
                                    'is-invalid': !meta.valid && meta.touched,
                                }"
                            >
                                <option value="" :disabled="true">請選擇</option>
                                <template v-for="item in store_type_option" :key="item">
                                    <option :value="item.value">{{ item.text }}</option>
                                </template>
                            </select>
                        </Field>
                        <button type="button" class="btn btn-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop" v-on:click="cancel()">新增</button>
                    </div>
                    <ErrorMessage name="role" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="subtype_name" class="form-label"><span class="required">*</span>店家子類型</label>
                    <Field name="subtype_name" v-slot="{ meta, field }" v-model="store_subtype_data.subtype_name">
                        <input
                            id="subtype_name"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="subtype_name" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="subtype_name_en" class="form-label">店家子類型（英文）</label>
                    <Field name="subtype_name_en" v-slot="{ meta, field }" v-model="store_subtype_data.subtype_name_en">
                        <input
                            id="subtype_name_en"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="subtype_name_en" class="invalid-feedback"/>
                </div>
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
                <div class="mb-3">
                </div>
            </Form>

            <!-- Modal -->
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-md">
                    <Form @submit="store_type_save()" :validation-schema="store_type_schema">
                        <div class="modal-content" style="color: #000000;">
                            <div class="modal-header text-center bg-dark text-white">
                                <h5 class="modal-title w-100" id="staticBackdropLabel">
                                    新增店家類型
                                </h5>
                                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" v-on:click="cancel()"></button>
                            </div>
                            <div class="modal-body">
                                <div class="col-12 row text-start mx-auto">
                                    <div class="col-12 mb-3">
                                        <div class="col-12">
                                            <label for="store_type_name" class="form-label"><span class="required">*</span>店家類型名稱</label>
                                            <Field name="store_type_name" v-slot="{ meta, field }" v-model="create_store_type_data.store_type_name">
                                                <input
                                                    id="store_type_name"
                                                    v-bind="field"
                                                    class="form-control"
                                                    :class="{
                                                        'is-valid': meta.valid && meta.touched,
                                                        'is-invalid': !meta.valid && meta.touched,
                                                    }"
                                                />
                                            </Field>
                                            <ErrorMessage name="store_type_name" class="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <div class="col-12">
                                            <label for="store_type_name_en" class="form-label">店家類型名稱（英文）</label>
                                            <Field name="store_type_name_en" v-slot="{ meta, field }" v-model="create_store_type_data.store_type_name_en">
                                                <input
                                                    id="store_type_name_en"
                                                    v-bind="field"
                                                    class="form-control"
                                                    :class="{
                                                        'is-valid': meta.valid && meta.touched,
                                                        'is-invalid': !meta.valid && meta.touched,
                                                    }"
                                                />
                                            </Field>
                                            <ErrorMessage name="store_type_name" class="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <div>
                                            <label for="multiple" class="form-label"><span class="required">*</span>倍數</label>
                                            <Field name="multiple" v-slot="{ meta, field }" v-model="create_store_type_data.multiple">
                                                <input
                                                    id="multiple"
                                                    v-bind="field"
                                                    class="form-control"
                                                    :class="{
                                                        'is-valid': meta.valid && meta.touched,
                                                        'is-invalid': !meta.valid && meta.touched,
                                                    }"
                                                    :min="1"
                                                    :max="10"
                                                    type="number"
                                                />
                                            </Field>
                                            <ErrorMessage name="multiple" class="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <div>
                                            <label for="order" class="form-label"><span class="required">*</span>排序</label>
                                            <Field name="order" v-slot="{ meta, field }" v-model="create_store_type_data.order">
                                                <input
                                                    id="order"
                                                    v-bind="field"
                                                    class="form-control"
                                                    :class="{
                                                        'is-valid': meta.valid && meta.touched,
                                                        'is-invalid': !meta.valid && meta.touched,
                                                    }"
                                                    :min="1"
                                                    :max="99999"
                                                    type="number"
                                                />
                                            </Field>
                                            <ErrorMessage name="multiple" class="invalid-feedback"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="submit" class="btn btn-primary" :disabled="modal_button_disabled">送出</button>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" v-on:click="cancel()">關閉</button>
                            </div>
                            <div class="row mx-3">
                                <div class="col-12 text-center mx-auto">
                                    <div class="alert alert-success form-floating" role="alert" v-if="modal_success_flag" style="white-space: pre-wrap;">
                                        {{ modal_success_message }}
                                    </div>
                                    <div class="alert alert-danger form-floating" role="alert" v-if="modal_error_flag" style="white-space: pre-wrap;">
                                        {{ modal_error_message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    get_store_type_detail,
    save_store_type,
    save_store_subtype,
} from '../../api/store_type';
import { mapActions, mapGetters } from 'vuex';
import { 
    get_store_type_option,
} from '../../api/data';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { 
    store_type_rules,
    store_subtype_rules
} from '../../rules/store/store_type';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            store_type_option: [],
            store_subtype_data: {
                type_id: "",
                subtype_name: "",
                subtype_name_en: "",
                type_name: "",
            },
            button_disabled: false,
            create_store_type_data: {
                store_type_name: "",
                store_type_name_en: "",
                multiple: "",
                order: "",
            },
            modal_button_disabled: false,
            modal_success_message: "",
            modal_success_flag: false,
            modal_error_message: "",
            modal_error_flag: false,
            can_go_back: false,

        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_store_type_detail({subtype_id: this.$route.params.id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else {
                    this.store_subtype_data = response.data.response_data;
                }
            })
        },
        cancel() {
            this.modal_button_disabled = false;
            this.modal_success_message = "";
            this.modal_success_flag = false;
            this.modal_error_message = "";
            this.modal_error_flag = false;
            this.create_store_type_data = {
                store_type_name: "",
                store_type_name_en: "",
                multiple: "",
                order: "",
            }
            let store_type_name_dom = document.getElementsByName("store_type_name");
            let store_type_name_en_dom = document.getElementsByName("store_type_name_en");
            let multiple_dom = document.getElementsByName("multiple");
            let order_dom = document.getElementsByName("order");
            store_type_name_dom[0].classList.remove("is-valid");
            store_type_name_dom[0].classList.remove("is-invalid");
            store_type_name_en_dom[0].classList.remove("is-valid");
            store_type_name_en_dom[0].classList.remove("is-invalid");
            multiple_dom[0].classList.remove("is-valid");
            multiple_dom[0].classList.remove("is-invalid");
            order_dom[0].classList.remove("is-valid");
            order_dom[0].classList.remove("is-invalid");
        },
        store_type_save() {
            this.modal_button_disabled = true;
            this.modal_success_message = "";
            this.modal_success_flag = false;
            this.modal_error_message = "";
            this.modal_error_flag = false;
            save_store_type(this.create_store_type_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.modal_success_message = response.data.message;
                    this.modal_success_flag = true;
                    get_store_type_option().then(response => {
                        if (response.data.success === false && response.data.check === false) {
                            this.user_logout();
                        } else {
                            this.store_type_option = response.data.response_data["TW"];
                        }
                    })
                }
            }, error => {
                this.modal_error_message = error.response.data.message;
                this.modal_error_flag = true;
                this.modal_button_disabled = false;
            });
        },
        store_subtype_save() {
            this.button_disabled = true;
            save_store_subtype(this.store_subtype_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'StoreTypeManagement' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "StoreTypeManagement"});
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.id !== undefined) {
            this.search();
        }
        get_store_type_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.store_type_option = response.data.response_data["TW"];
            }
        })
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
        store_type_schema() {
           return store_type_rules;
        },
        store_subtype_schema() {
           return store_subtype_rules;
        },
    }
};
</script>
