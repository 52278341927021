import * as yup from 'yup';

export const store_type_rules = yup.object({
    store_type_name: yup.string().max(50, "店家類型名稱至多為50個字").required('店家類型名稱尚未填寫'),
    store_type_name_en: yup.string().max(50, "店家類型名稱（英文）至多為50個字"),
    multiple: yup.number().typeError("必須為數字").min(1, "最小值為1").max(10, "最大值為10").required("點數倍數尚未填寫"),
    order: yup.number().typeError("必須為數字").min(1, "最小值為1").max(99999, "最大值為99999").required("排序尚未填寫"),
})

export const store_subtype_rules = yup.object({
    store_type: yup.string().required('店家類型尚未選擇'),
    subtype_name: yup.string().max(50, "店家子類型名稱至多為50個字").required('店家子類型名稱尚未填寫'),
    subtype_name_en: yup.string().max(50, "店家子類型名稱（英文）至多為50個字"),
})